.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* PARA EL COLOR DEL LINK DE LOS BOTONES DE LAS OPCIONES*/
.App-link {
  color: #61dafb;
}

/* Color de fondo de la barra */
.barra {
  background-color: #009edb;
}

/* Clase para los paneles izquierdo y derecho de la aplicacion */
.tamanoColorPaneles {
  position: relative;
  top: 85px;
  height: 594px;
  border-radius: 10px;
  margin-left: 22px;
  margin-right: 22px;

  background-color: #142d34;
}

.tamaño-Fila-Login {
  height: 100vh;
  width: 100vw;
}
/*Color de fondo y animacion del panel superior del componente pacientes */
.animacionFilaSuperior-Pacientes {
  background-color: rgb(216, 227, 231);

  height: 270px;
  border-radius: 10px;
  animation: myAnim 1s linear 0s 1 alternate both;
}

/*Animacion del panel */
@keyframes myAnim {
  0% {
    opacity: 0;
    transform: rotateX(-100deg);
    transform-origin: top;
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
    transform-origin: top;
  }
}

/* Color de fondo y animacion de la fila inferior del panel pacientes */
.animacionFilaInferior-Pacientes {
  background-color: rgb(216, 227, 231);
  animation: myAnim 1s linear 0s 1 alternate both;
  height: 240px;
  border-radius: 10px;
}

/* Clase para el div de las citas medicas del panel izquierdo */
.tamanoListaCitasMedicas {
  overflow-y: scroll;
  scroll-behavior: smooth;
  display: block;
  width: 380px;
  height: 350px;

  color: rgb(82, 196, 211);
  background-color: rgb(216, 227, 231);
}

/* Clase para el div de los compromisos del panel izquierdo */
.tamanoListaCompromisos {
  background-color: rgb(216, 227, 231);

  display: block;
  height: 145px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/* Clase para los titulos de los divs internos */
.Titulos {
  font-size: 27px;
  font-weight: bold;
  color: #52c4d3;
  text-align: center;
}

/* ScrollBar del div interno de las citas medicas */
.tamanoListaCitasMedicas::-webkit-scrollbar {
  width: 7px;

  background-color: rgb(20, 45, 52);
}

/* THUM DEL SCROL DEL DIV DE LAS CITAS MEDICAS */
.tamanoListaCitasMedicas::-webkit-scrollbar-thumb {
  background-color: rgb(82, 196, 211);
}

/* ScrollBar DEL DIV INTERNO DE LOS COMPROMISOS */
.tamanoListaCompromisos::-webkit-scrollbar {
  width: 7px;

  background-color: rgb(20, 45, 52);
}

/* THUM DEL SCROL DEL DIV DE LOS COMPROMISOS */
.tamanoListaCompromisos::-webkit-scrollbar-thumb {
  background-color: rgb(82, 196, 211);
}

.textarea {
  resize: none;
}

.test {
  background-color: #009edb;
}

.nav-link.active {
  background-color: #e0f6ff;
}

.pruebaPanel {
  background: rgba(0, 158, 219, 0.8);

  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 10px;
}

.lotesTamano {
  height: 300px;
}

/* Winner Formulario   */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.tn {
  font-family: "Montserrat", sans-serif;
}
.tnt {
  font-weight: 900 !important;
  color: #009fdb;
}
.tns {
  font-weight: 700 !important;
  color: #323070;
}
.formul {
  padding-top: 2em;
}
.topito {
  padding-top: 2em;
}
.h2 {
  padding-bottom: 0.5em;
}
.btnosnet {
  background: #009fdb !important;
  border: 0 !important;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
  margin-top: 1em;
}
.ttab.active {
  color: #009fdb !important;
}
.ttab {
  color: #323070 !important;
}
.bgosnet {
  background-image: url("./img/formbg.png");
  background-size: cover;
}

.FondoFila {
  height: 905px;
}

.animacionPanel {
  animation: myAnim 1s ease 0s 1 normal forwards;
}

@keyframes myAnim {
  0% {
    opacity: 0;
    transform: rotateX(-100deg);
    transform-origin: top;
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
    transform-origin: top;
  }
}

.animacionLogin {
  animation: myAnimPanel 1s ease 0s 1 normal forwards;
}

@keyframes myAnimPanel {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.cajaListaCircuitos {
  border: 1px solid rgb(50, 48, 112);

  height: 280px;

  overflow-y: scroll;
  scroll-behavior: smooth;
}

.cajaListaCircuitos::-webkit-scrollbar {
  width: 7px;

  background-color: rgb(255, 255, 255);
}

.cajaListaCircuitos::-webkit-scrollbar-thumb {
  background-color: #009fdb;
}

.tamanoCajaPrincipal {
  height: 1000px;
}

/* ANIMACION PARA EL SIMBOLO DE CARGA CUANDO SE HAGAN LAS PETICIONES */
.animacionCarga {
  animation: GIRAR 1s ease 0s infinite normal forwards;
}
@keyframes GIRAR {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fondoL {
  border-left: 10px solid #61dafb;
}
